import React from "react";
import "./ExperienceCard.css";

const ExperienceCard = ({ details }) => {
  return (
    <div className="work-experience-card">
      <div className="t-image">
        <img src={details.icon} alt="project image" />
        <div className="card-title">{details.title}</div>
        <div className="card-content">
          <h4>{details.title}</h4>
          <p className="work-duration">{details.date}</p>
          <div className="responsibility-list">
            {details.responsibilities.map((responsibility, index) => (
              <span key={index} className="responsibility-item">
                {responsibility}
              </span>
            ))}
            <p className="mission-details">{details.mission}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;
