import React, { useEffect, useRef } from 'react';
import moi from "../../assets/images/img12.png";
import SocialLinks from "../SocialMedia/SocialLinks";
import "./About.css";

export default function About() {
  const aboutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 } // Déclencher l'animation quand 10% de la section est visible
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  return (
    <section id='about'>
      <h5>A propos de moi </h5>
      <main className="main-about" ref={aboutRef}>
        <div className="avatar">
          <img src={moi} alt="Image" />
        </div>
        <div className="about-container">
          <section className="about-me">
            <h1 className="hello">Hi!</h1>
            <h2 className='name'>Je suis Amin</h2>
            <p className="description">
              <strong>Développeur web</strong> passionné depuis mes 16 ans. Avec un bac STI2D en Systèmes d'Information Numérique et un bac +2 en développement web full stack et mobile spécialisé en cybersécurité, j'ai acquis une solide expertise.
              Ayant réalisé divers projets en freelance, je maîtrise particulièrement React pour créer des applications web innovantes et performantes. Je suis prêt à transformer vos idées en réalités numériques, que ce soit pour un site vitrine, une application web complexe ou améliorer votre présence en ligne.
              <br/> Contactez-moi pour discuter de vos projets et voir comment je peux vous aider à atteindre vos objectifs numériques.
            </p>
          </section>
          <SocialLinks />
        </div>
      </main>
    </section>
  );
}
