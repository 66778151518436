export const SKILLS = [
  {
    title: "Frontend",
    icon: "../../assets/images/frontend-icon.png",
    skills: [
      { skill: "HTML5/CSS", percentage: "80%" },
      { skill: "JavaScript", percentage: "75%" },
      { skill: "React.js", percentage: "60%" },
      { skill: "Tailwind CSS", percentage: "50%" },
    ],
  },
  {
    title: "Backend",
    icon: "../../assets/images/backend-icon.png",
    skills: [
      { skill: "PHP", percentage: "70%" },
      { skill: "Symfony", percentage: "65%" },
      { skill: "Doctrine ORM", percentage: "65%" },
      { skill: "SQL", percentage: "65%" },
      { skill: "MySQL", percentage: "65%" },
    ],
  },
  {
    title: "Conception",
    icon: "../../assets/images/tools-icon.png",
    skills: [
      { skill: "Diagrammes (UML)", percentage: "65%" },
      { skill: "Modèles conceptuels (MCD/MLD)", percentage: "50%" },
      { skill: "Maquettage (UX/UI)", percentage: "70%" },
      { skill: "Cahier des charges", percentage: "50%" },
    ],
  },
  {
    title: "Soft Skills",
    icon: "../../assets/images/soft-skills-icon.png",
    skills: [
      { skill: "Curieux", percentage: "80%" },
      { skill: "Esprit d'équipe", percentage: "85%" },
      { skill: "Rigoureux", percentage: "75%" },
      { skill: "Communicant", percentage: "75%" },
      { skill: "Autonome", percentage: "75%" },
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    title: "Explorateur Freelance pour Mob",
    icon: "../../assets/images/cardMob.png",
    iconUrl: "../../assets/images/global.png",
    iconGit: "../../assets/images/github.png",
    linkWeb: "https://mobconciergerie.com/",
    date: "Janvier 2024 - Février 2024",
    responsibilities: [
      "ReactJS",
      "HTML5",
      "CSS3",
      "JavaScript",
    ],
    mission: "Développement site web pour une conciergerie."
  },
  {
    title: "Explorateur Freelance pour EnidWeb",
    icon: "../../assets/images/cardEnid.png",
    iconUrl: "../../assets/images/global.png",
    iconGit: "../../assets/images/github.png",
    linkWeb: "https://enidweb.fr/",
    date: "Janvier 2024 - Aujourd'hui",
    responsibilities: [
      "ReactJS",
      "HTML5",
      "CSS3",
      "JavaScript",
      "Tailwind CSS"
    ],
    mission: "Création sites web pour une agence web."
  },
  {
    title: "Challenges Spikes Front",
    icon: "../../assets/images/cardPrice.png",
    iconUrl: "../../assets/images/global.png",
    iconGit: "../../assets/images/github.png",
    date: "Juillet 2024",
    responsibilities: [
      "HTML5",
      "CSS3",
      "React"
    ],
    mission: "Créer un affichage d'abonnement pour le challenge Spike."
  },

  {
    title: "Challenges Spikes Front",
    icon: "../../assets/images/cardPrice.webp",
    iconUrl: "../../assets/images/global.png",
    iconGit: "../../assets/images/github.png",
    date: "Juillet 2024",
    responsibilities: [
      "HTML5",
      "CSS3",
      "React"
    ],
    mission: "Créer un affichage d'une card avec étoiles pour le challenge Spike."
  },
];
