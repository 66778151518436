import React, { useEffect } from 'react';
import './Hero.css';

const Hero = () => {
  useEffect(() => {
    const txtElement = document.querySelector('.typewriter');
    const words = ["Next Gen" , "Nouvelle génération "];
    const wait = 5000;
    let txt = '';
    let wordIndex = 0;
    let isDeleting = false;
    let typeSpeed = 300;

    const type = () => {
      const current = wordIndex % words.length;
      const fullTxt = words[current];

      if (isDeleting) {
        txt = fullTxt.substring(0, txt.length - 1);
      } else {
        txt = fullTxt.substring(0, txt.length + 1);
      }

      txtElement.innerHTML = `<span class="txt">${txt}</span>`;

      if (!isDeleting && txt === fullTxt) {
        typeSpeed = wait;
        isDeleting = true;
      } else if (isDeleting && txt === '') {
        isDeleting = false;
        wordIndex++;
        typeSpeed = 500;
      } else {
        typeSpeed = isDeleting ? 100 : 200;
      }

      setTimeout(() => type(), typeSpeed);
    };

    type();
  }, []);

  return (
    <section id="hero" className="hero-container">
      <div className="hero-content animate-left">
        <h2>
          Portfolio d'un Développeur <br />
          <span className="typewriter special-font"></span>
        </h2>
        <p>
        Bienvenue dans mon portfolio, votre passerelle vers une collaboration dynamique et créative ! Découvrez comment je peux enrichir votre équipe ou apporter mes services en freelance.
        </p>
      </div>

      <div className="hero-img animate-right">
        <div>
          <img src="./assets/images/img11.png" alt="" />
        </div>

        <div>
          <div className="tech-icon">
            <img src="./assets/images/img02.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img03.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img04.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img05.png" alt="" />
          </div>
        </div>

        <div>
          <div className="tech-icon">
            <img src="./assets/images/img01.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img07.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img06.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
