import React from 'react';
import instagramLogo from '../../assets/images/instagram.png';
import twitterLogo from '../../assets/images/twitter.png';
import githubLogo from '../../assets/images/github-mark-white.png';
import maltLogo from '../../assets/images/malt-svgrepo-com.svg';
import linkedinLogo from '../../assets/images/linkedin.png';
import './SocialLinks.css';

const socialLinks = [
  {
    name: "Instagram",
    link: "https://www.instagram.com/nimaa.dev",
    icon: instagramLogo,
    title: "Instagram",
  },
  {
    name: "Twitter",
    link: "https://twitter.com/",
    icon: twitterLogo,
    title: "Twitter",
  },
  {
    name: "GitHub",
    link: "https://github.com/Nimaa31",
    icon: githubLogo,
    title: "GitHub",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/amin-eddine-mito",
    icon: linkedinLogo,
    title: "Malt",
  },
  {
    name: "Malt",
    link: "https://www.malt.fr/profile/aminmito",
    icon: maltLogo,
    title: "Malt",
  },
];

const SocialLinks = () => {
  return (
    <div className="social-links">
      {socialLinks.map((link, index) => (
        <a key={index} href={link.link} target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={link.icon} alt={link.title} />
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
